import React, { Component, Fragment } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

class Date extends Component {
  constructor(props) {
    super(props);
    this.state = { data: '' };
  }

  componentDidMount() {
    axios
      .get(`http://covid19.assohelp.org/date.php`)
      .then(res => {
        this.setState({
          data: res.data[0].date
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }
  $;
  render() {
    const { data } = this.state;
    //moment.locale('fr');
    return (
      <Fragment>
        <p>
          {moment(data)
            .format('kk:mm - D MMMM YYYY')
            .replace(':', 'h')}
        </p>
      </Fragment>
    );
  }
}

export default Date;
