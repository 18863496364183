import React from 'react';

const TableCount = ({ data }) => {
  const last = data && data[data.length - 1];
  const av_last = data && data[data.length - 2];

  console.log(av_last);
  console.log(last);
  const newCas =
    data &&
    (last['Nombre_cas_confirmés_par_jour'] * 100) /
      av_last['Nombre_cas_confirmés_par_jour'];
  const newCasF = (Math.round(newCas * 100) / 100).toFixed(2);

  const newLive =
    data && (last['new_Rétablis'] * 100) / av_last['new_Rétablis'];
  const newLiveF = (Math.round(newLive * 100) / 100).toFixed(2);

  const newdeath = data && (last['new_Décès'] * 100) / av_last['new_Décès'];
  const newdeathF = (Math.round(newdeath * 100) / 100).toFixed(2);
  //(isFinite(newdeath) && newdeath) || `+${last['new_Décès']}`;

  console.log(newLive);
  console.log(newLiveF);
  return (
    <table className="Numbers__Table-sc-1khuw7p-1 hyNirF">
      <tbody>
        <tr>
          <td className="heading">Nombre de cas</td>
          <td className="heading" colSpan="2" style={{ textAlign: 'center' }}>
            Par rapport à hier
          </td>
        </tr>
        <tr>
          <td className="double">
            <span>Confirmés&nbsp;&nbsp;الحالات </span>
            <span style={{ color: '#FD7272' }}>
              {data && last['Total_nombre_cas_confirmés']}
            </span>
          </td>
          <td style={{ color: '#FD7272' }}>
            +&nbsp;{data && last['Nombre_cas_confirmés_par_jour']} cas
          </td>
          <td style={{ color: '#FD7272' }}>
            {(isFinite(newCasF) && newCasF + '%') ||
              `+${last['Nombre_cas_confirmés_par_jour']}`}
            {/*+&nbsp;{(Math.round(newCas * 100) / 100).toFixed(2)}&nbsp;%*/}
          </td>
        </tr>
        <tr>
          <td className="double">
            <span>Rétablis&nbsp;&nbsp;&nbsp;المتعافين </span>
            <span style={{ color: '#82ca9d' }}>{data && last['Rétablis']}</span>
          </td>
          <td style={{ color: '#82ca9d' }}>
            {data && last['new_Rétablis']} cas
          </td>
          <td style={{ color: '#82ca9d' }}>
            {(isFinite(newLiveF) && newLiveF + '%') ||
              `+${last['new_Rétablis']}`}
          </td>
        </tr>
        <tr>
          <td className="double">
            <span>Décès&nbsp;&nbsp;&nbsp;الوفيات</span>
            <span style={{ color: '#182C61' }}>{data && last['Décès']}</span>
          </td>
          <td style={{ color: '#182C61' }}>{data && last['new_Décès']} cas</td>
          <td style={{ color: '#182C61' }}>
            {(isFinite(newdeathF) && newdeathF + '%') ||
              `+${last['new_Décès']}`}
            {/*(Math.round(newdeathF * 100) / 100).toFixed(2)*/}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default TableCount;
