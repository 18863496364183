import React, { Component, Fragment } from 'react';
import './index.css';
import axios from 'axios';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import TableCount from './TableCount';
import insta from './insta.png';
import logo from './logo.png';
import hashtags from './hashtags.png';
import Date from './Date';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, width: null, height_leg: null };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    axios
      .get(`http://covid19.assohelp.org/json_php.php`)
      .then(res => {
        this.setState({
          data: res.data.map(function(i) {
            delete i['id'];
            return i;
          }),
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
    if (window.innerWidth < 768) {
      this.setState({
        height_leg: 70
      });
    }
    if (window.innerWidth > 200) {
      this.setState({
        width: 350
      });
    }

    if (window.innerWidth > 500) {
      this.setState({
        width: 500
      });
    }

    if (window.innerWidth > 768) {
      this.setState({
        width: 700,
        height_leg: 36
      });
    }
    if (window.innerWidth > 1000) {
      this.setState({
        width: 900
      });
    }
    if (window.innerWidth > 1200) {
      this.setState({
        width: 1000
      });
    }
  }
  componentDidUpdate() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  updateDimensions() {
    //window.location.reload();
  }

  renderCourbe() {
    const { data, width, height_leg } = this.state;
    data &&
      data.forEach(item => {
        item.Nombre_cas_confirmés_par_jour = parseInt(item.confimed_cases);
        item.Total_nombre_cas_confirmés = parseInt(item.total_confirmed);
        item.Décès = parseInt(item.deaths);
        item.Rétablis = parseInt(item.recovered);
        item.new_Décès = parseInt(item.new_deaths);
        item.new_Rétablis = parseInt(item.new_recovered);
        delete item.recovered;
        delete item.deaths;
        delete item.new_recovered;
        delete item.new_deaths;
        delete item.total_confirmed;
        delete item.confimed_cases;
      });

    //find the index of object from array that you want to update
    const objIndex = data && data.findIndex(obj => obj.date === '01-01');

    // make new object of updated object.
    const updatedObj = data && { ...data[objIndex], date: '0' };

    // make final new array of objects by combining updated object.
    const updatedProjects = data && [
      ...data.slice(0, objIndex),
      updatedObj,
      ...data.slice(objIndex + 1)
    ];

    return (
      <LineChart
        width={width}
        height={500}
        data={updatedProjects}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Legend verticalAlign="top" iconSize={16} height={height_leg} />
        <Line
          type="monotone"
          dataKey="Nombre_cas_confirmés_par_jour"
          stroke="#8884d8"
          strokeWidth="3"
          activeDot={{ r: 4 }}
        />
        <Line
          type="monotone"
          dataKey="Total_nombre_cas_confirmés"
          stroke="#FD7272"
          activeDot={{ r: 8 }}
          strokeWidth="3"
        />
        <Line
          type="monotone"
          dataKey="Rétablis"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
          strokeWidth="3"
        />
        <Line
          type="monotone"
          dataKey="Décès"
          stroke="#d63031"
          strokeWidth="2"
          activeDot={{ r: 2 }}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
      </LineChart>
    );
  }

  render() {
    const { data, loading } = this.state;

    return (
      <Fragment>
        <a
          className="logo"
          title="assohelp.org"
          href="https://www.assohelp.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} />
        </a>
        <header className="header">
          <h1>ÉVOLUTION DE LA COVID-19 AU MAROC</h1>
          <a
            title="Assohelp"
            href="https://www.instagram.com/assohelp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insta} />
          </a>
        </header>
        <div className="container">
          <div className="row">
            <div className="info">
              <div className="wrap-info">
                <div className="info1">
                  <p>ألو 141 للمساعدة الطبية الإستعجالية</p>
                  <p>Allô SAMU 141</p>
                </div>
                <div className="hashtags">
                  <a
                    href="https://www.instagram.com/assohelp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={hashtags} />
                  </a>
                </div>
                <div className="info2">
                  <p>ألو اليقظة الوبائية </p>
                  <p>080 100 47 47 :: الرقم الأخضر</p>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="load">
                <Loader
                  type="Triangle"
                  visible={loading}
                  color="#FD7272"
                  height={80}
                  width={80}
                />
              </div>
            ) : (
              <Fragment>
                <div className="courbe">
                  <h2>Évolution des cas</h2>
                  {this.renderCourbe()}
                </div>

                <div className="table">
                  <h2>Données les plus récentes</h2>
                  <TableCount data={data} />
                  <div className="time">
                    <p>Dernière MAJ</p>
                    <Date />
                    <p>إلى حدود</p>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default App;
